.vl-header {
  .site-bar {
    &__logo {
      vertical-align: middle;
      padding-left: 1rem;
      position: absolute;
      right: 4rem;
      top: 0.5rem;

      @media only screen and (min-width: $vl-bp-medLarge) {
        position: relative;
        right: 0;
        top: 0;
      }

      &--image {
        width: 3.8rem;
        height: 3.6rem;
        float: left;
      }
    }

    &__link {
      transition: opacity 0.55s ease, margin 0.5s ease;
      height: 100%;
      margin-left: 0;
      padding: 0;
      text-decoration: none;
      @media only screen and (min-width: $vl-bp-medLarge) {
        padding: 0;
        height: $site-bar-height;
      }
    }

    &__separator {
      padding: 1rem;
      height: 100%;
      border: none;

      @media only screen and (min-width: $vl-bp-medLarge) {
        padding: 0 1rem;
        margin-left: 1rem;
        height: auto;
        line-height: $site-bar-height;
        border-left: 0.1rem solid $vl-border-color;
      }
    }

    &__title {
      border-bottom: 0.1rem solid transparent;
      color: $vl-black;
      font-size: 1.4rem;
      font-weight: 800;
      text-transform: uppercase;
      transition: border-bottom-color 0.2s;
    }

    &__mobileTitle {
      .logo_pluxee {
        display: block;
        width: 11rem;
        height: 4rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  &.mobileNavIsOpen {
    .site-bar {
      &__logoLink {
        display: none;

        @media only screen and (min-width: $vl-bp-medLarge) {
          display: inline-flex;
        }
      }
      &__link,
      &__logo,
      &__separator {
        display: none;

        @media only screen and (min-width: $vl-bp-medLarge) {
          display: inline-block;
        }
      }
    }
  }
}
